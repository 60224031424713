<template>
  <v-dialog v-model="open" v-bind="$attrs">
    <v-card>
      <v-container>
        <v-row>
          <v-col>
            <v-btn-toggle>
            <v-btn @click="zoom(2)">
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>

            <v-btn  @click="zoom(0.5)">
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
            </v-btn-toggle>
            <cropper
              ref="cropper"
              class="cropper"
              image-restriction="stencil"
              :src="image.src"
              :stencil-component="$options.components.CircleStencil"
              :stencil-size="{
                width: 512,
                height: 512,
              }"
              :stencil-props="{
                handlers: {},
                movable: false,
                resizable: false,
                aspectRatio: 1,
              }"
              :debounce="false"
              :canvas="{
                minHeight: 512,
                minWidth: 512,
                maxHeight: 512,
                maxWidth: 512,
              }"
              @change="onChange"
            ></cropper>
          </v-col>

          <v-col>
            <p>Preview:</p>

            <preview :width="120" :height="120" :image="result.image" :coordinates="result.coordinates"></preview>
            <preview
              class="rounded-circle"
              :width="120"
              :height="120"
              :image="result.image"
              :coordinates="result.coordinates"
            ></preview>
          </v-col>

          <v-col cols="12">
            <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an avatar"
              prepend-icon="mdi-camera"
              @change="onFileUpload"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-btn dark @click="open = false">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="uploadImage" :disabled="!result.canvas || uploading" :loading="uploading">{{ 'Upload' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper, CircleStencil, Preview } from 'vue-advanced-cropper';
import { Client } from '@/services';
import { User } from '@/models/orm/Hierarchy';
import { Services } from '@/store/actions/api';
import { jsonToFormData } from '@/utils/http-common';

import 'vue-advanced-cropper/dist/style.css';
import { captureException } from '@sentry/vue';

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}

export default {
  name: 'AvatarHandler',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
    },
  },

  components: {
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
    Preview,
  },

  data() {
    return {
      image: {
        src: null,
        type: null,
      },
      result: {
        cordinates: null,
        image: null,
        canvas: null,
      },

      uploading: false,

      rules: [(value) => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!'],
    };
  },

  computed: {
    open: {
      get() { return this.value; },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    zoom(factor) {
			this.$refs.cropper.zoom(factor);
		},

    reset() {
      this.image = {
        src: null,
        type: null,
      };
      this.result = {
        cordinates: null,
        image: null,
        canvas: null,
      };
    },

    onFileUpload(file) {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }

      if (!file) {
        this.reset();
      }

      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // Create a new FileReader to read this image binary data
      const reader = new FileReader();
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e) => {
        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
        this.image = {
          // Read image as base64 and set it as src:
          src: blob,
          // Determine the image type to preserve it during the extracting the image from canvas:
          type: getMimeType(file, file.type),
        };
      };
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(file);
    },

    onChange({ coordinates, image, canvas }) {
      this.result = {
        coordinates,
        image,
        canvas,
      };
    },


    async configureAvatar(image) {
      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.ConfigureAvatar.key,
        };

        let payload = Services.FlightScopeUser.methods.ConfigureAvatar.params;

        payload.AvatarBase64 = image;

        const data = jsonToFormData(payload);

        // eslint-disable-next-line no-await-in-loop
        const resp = await Client({ params, data, method: 'post' });

        if (resp?.data) {
          User.update({
            where: this.userId,
            data: {
              AvatarURL: resp.data,
            },
          });

          this.open = false;
        }

        return true;

      } catch (error) {
        captureException(error);

        return false;
      }
    },

    async uploadImage() {
      this.uploading = true;

      const { canvas } = this.result;
      if (canvas) {
        // const newTab = window.open();
			  // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL()}"></img>`;

        await this.configureAvatar(canvas.toDataURL());
      }
      this.uploading = false;
    },
  },

  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};
</script>

<style scoped>
.cropper {
  height: 300px;
  width: 300px;
  background: #ddd;
}
</style>

<template>
  <v-card elevation="1">
    <v-list>
      <v-list-item three-line>
        <v-list-item-avatar size="97" rounded="circle">
          <v-img :src="user.Avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ user.DisplayName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.Email }}
          </v-list-item-subtitle>

          <v-list-item-action class="ml-0">
            <v-btn @click="avatarDialog = true" :loading="avatarDialog" small color="success">{{ 'Change profile picture' }}</v-btn>
          </v-list-item-action>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <avatar-handler v-model="avatarDialog" :user-id="user.ID" max-width="600"></avatar-handler>
  </v-card>
</template>

<script>
import { User } from '@/models/orm/Hierarchy';
import AvatarHandler from './AvatarHandler.vue';

export default {
  name: 'UserCard',

  props: {
    user: {
      type: User,
      required: true,
    },
  },

  components: {
    AvatarHandler,
  },

  data() {
    return {
      avatarDialog: false,
    };
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',_vm._b({model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},'v-dialog',_vm.$attrs,false),[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-btn-toggle',[_c('v-btn',{on:{"click":function($event){return _vm.zoom(2)}}},[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")])],1),_c('v-btn',{on:{"click":function($event){return _vm.zoom(0.5)}}},[_c('v-icon',[_vm._v("mdi-magnify-minus-outline")])],1)],1),_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"image-restriction":"stencil","src":_vm.image.src,"stencil-component":_vm.$options.components.CircleStencil,"stencil-size":{
              width: 512,
              height: 512,
            },"stencil-props":{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: 1,
            },"debounce":false,"canvas":{
              minHeight: 512,
              minWidth: 512,
              maxHeight: 512,
              maxWidth: 512,
            }},on:{"change":_vm.onChange}})],1),_c('v-col',[_c('p',[_vm._v("Preview:")]),_c('preview',{attrs:{"width":120,"height":120,"image":_vm.result.image,"coordinates":_vm.result.coordinates}}),_c('preview',{staticClass:"rounded-circle",attrs:{"width":120,"height":120,"image":_vm.result.image,"coordinates":_vm.result.coordinates}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"rules":_vm.rules,"accept":"image/png, image/jpeg, image/bmp","placeholder":"Pick an avatar","prepend-icon":"mdi-camera"},on:{"change":_vm.onFileUpload}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){_vm.open = false}}},[_vm._v("Close")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.result.canvas || _vm.uploading,"loading":_vm.uploading},on:{"click":_vm.uploadImage}},[_vm._v(_vm._s('Upload'))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }